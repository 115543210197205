import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@teamfoster/sdk/icon';
import { RouterModule } from '@angular/router';
import { ImageModule } from '@teamfoster/sdk/image';
import { Image } from '@teamfoster/sdk/image';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { CoreModule } from 'src/app/core/core.module';
import { BackgroundComponent } from '../background/background.component';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, IconModule, RouterModule, ImageModule, InViewModule, TextUtilityModule, BackgroundComponent],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input({ required: true }) title!: string;
  @Input({ required: false }) subtitle!: string;
  @Input({ required: false }) intro!: string;
  @Input({ required: false }) image!: Image;
  @Input() centerLeftColumn = false;
  @Input() cssClasses = '';

  @Output() inView = new EventEmitter<any>();
  inviewPort = false;

  constructor() {}

  inViewChange(e: boolean) {
    this.inviewPort = e;
    if (e) {
      this.inView.emit(null);
    }
  }
}
