<header class="header position-relative" [ngClass]="cssClasses">
  <div class="container">
    <ng-content select="[header]"></ng-content>

    <div class="row position-relative z-1 section-inset-y">
      <div class="col-md-7 col-lg-6" [class.mx-auto]="centerLeftColumn">
        <div class="d-flex flex-column gap-md-3 align-items-start">
          <h1 class="mb-0">{{ title }}</h1>

          <!-- intro part -->
          <div class="intro mb-3">
            @if (subtitle) {
              <h2 class="fs-3 text-primary mb-4">{{ subtitle }}</h2>
            }

            @if (intro) {
              <p class="fs-5">{{ intro | stripHtml }}</p>
            }
          </div>

          <ng-content select="[body]"></ng-content>
        </div>
      </div>

      @if (image) {
        <div class="col-5 col-lg-6 d-none d-md-block">
          <div class="pull-e position-relative h-100">
            <fstr-image class="header__image" [image]="image"></fstr-image>
          </div>
        </div>
      }

      <div class="col-12">
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  </div>
  <app-background></app-background>
</header>
